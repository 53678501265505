
export function getUrlParams() {
    let url = window.location.href
    let dz_url = url.split('#')[0]
    if (dz_url === undefined) {
        return {}
    }
    let cs = dz_url.split('?')[1]
    if (cs === undefined) {
        return {}
    }
    let cs_arr = cs.split('&')
    let result = {}

    for(let i=0; i<cs_arr.length; i++){
        result[cs_arr[i].split('=')[0]] = cs_arr[i].split('=')[1]
    }
    return result
}