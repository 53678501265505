<template>
 <!-- 正常修改界面 -->
 <div v-if="state === 0">
   <h1>DTG ID 账户密码重置</h1>
   <h4>{{ userEmail }} {{ userName }}</h4>
   <div style="height: 15px"></div>
   <el-input v-model="password" placeholder="请输入新密码" class="input1" show-password> </el-input>
   <div style="height: 15px"></div>
   <el-input v-model="password2" placeholder="请重复输入新密码" class="input1" show-password> </el-input>
   <div style="height: 40px"></div>
   <el-button class="input1" type="primary" @click="confirmEdit">立刻修改</el-button>
 </div>
 <!-- token过期界面 -->
  <div v-if="state === 1">
    <el-result icon="error" title="Token已过期" sub-title="请重新申请修改密码"></el-result>
  </div>
<!-- 修改成功界面 -->
  <div v-if="state === 2">
    <el-result icon="success" title="密码修改成功" sub-title="请使用新密码进行登录"></el-result>
  </div>
</template>

<style scoped>
  .input1 {
    width: 300px;
    height: 40px;
  }
</style>

<script>

import { getData, postData } from "@/utils/request";
import { getUrlParams } from "@/utils/url"
export default {
  name: "EditPage",
  data() {
    return {
      userName: "Name",
      userEmail: "Email",
      password: "",
      password2: "",
      state: 1,
      token: ""
    }
  },
  methods: {
    confirmEdit() {
      const _this = this
      if (this.password !== this.password2) {
        this.$alert("两次密码不一致")
        return
      }
      postData('/email_edit_password', {
        'token': this.token,
        'password': this.password
      }).then(function (res) {
        console.log(res)
        if (res.msg === 'Success') {
          _this.state = 2
        }
      })
    }
  },
  mounted() {
    const _this = this
    let parse = getUrlParams()
    if (parse.token === undefined) {
      this.state = 1
      return
    }
    getData('/email_edit_get_info', {
      'token': parse.token
    }).then(function (res) {
      console.log(res)
      if (res.data !== undefined) {
        _this.userName = res.data.name
        _this.userEmail = res.data.email
        _this.state = 0
        _this.token = parse.token
      } else {
        _this.state = 1
      }
    })
  }

}


</script>
