import axios from 'axios'

axios.defaults.headers = {
    'Content-Type': 'application/json;charset=utf8',
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE, UPDATE',
    'Access-Control-Expose-Headers': 'Content-Length, Access-Control-Allow-Origin, Access-Control-Allow-Headers, Cache-Control, Content-Language, Content-Type',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    withCredentials: true
    // token: Cookies.get("system_token") || ""
}

axios.defaults.baseURL = 'https://service-56c4juj1-1305003202.bj.apigw.tencentcs.com/release'

// Response interceptors
axios.interceptors.response.use(
    (response) => {
        let res = response.data
        return res
    }
)

export function formateURLOnlyGet(link, json) {
    let url = link
    const data = Object.entries(json)

    if (data.length) {
        url += url.indexOf('?') === -1 ? '?' : ''
        url += Object.entries(data)
            .map(item => {
                return item[1].join('=')
            })
            .join('&')
    }
    return url
}

export function getData(url, json) {
    return axios
        .get(formateURLOnlyGet(url, json))
        .then(res => res)
        .catch(error => error)
}

export async function postData(url, json) {
    return await axios
        .post(url, json)
        .then(res => res)
        .catch(error => error)
}
